
export function checkTouch(){

  window.HAS_TOUCH = false;

  window.addEventListener('touchstart', function onFirstTouch() {
    // we could use a class
    document.body.classList.add('has-touch');

    window.HAS_TOUCH = true;
    // we only need to know once that a human touched the screen, so we can stop listening now
    window.removeEventListener('touchstart', onFirstTouch, false);
  }, false);
}
