import { aflAddEventListener, toggleCSSClass, slideToggle } from './utils';

/* Accordion functionality that is used on FAQ items when opening and closing them */
(function(window) {

  /** Accordion Component Behaviours */
  aflAddEventListener('.js-accordion-trigger', 'click', (ev) => {
    let el = ev.target;
    toggleCSSClass(el, 'active');
    let accordionContentEl = el.closest('.js-accordion-item').querySelector('.js-accordion-content');
    let accordionContentE2 = el
      .closest(".js-accordion-item")
      .querySelector(".js-accordion-trigger");
    if (accordionContentEl){
      slideToggle(accordionContentEl, accordionContentE2);
    }

  });

})(window);

// See all FAQ functionality

if (document.getElementsByClassName("faq-container").length > 0) {
  const faqContainer = document.querySelector(".faq-container");
  const accordion = faqContainer.querySelector(".accordion");
  const allFAQ = document.querySelector(".see-all-faq");

  if (allFAQ && accordion.childElementCount > 1) {
    allFAQ.classList.remove("see-all-faq-d-none");
    allFAQ.classList.add("see-all-faq-link");
  } else {
    allFAQ.classList.remove("see-all-faq-link");
    allFAQ.classList.add("see-all-faq-d-none");
  }
}