import { aflAddEventListener } from './utils';

/* Functions to help play videos embedded into various pages */
(function(window) {

  aflAddEventListener('.header-image-container', 'click', (ev) => {
    ev.preventDefault();
    let videoIframeEl = document.getElementById("#video-iframe");
    if (videoIframeEl != null){
      ev.target.style.display = 'none';
      videoIframeEl.src += "&autoplay=1";
      ev.preventDefault();
    }
  });

  aflAddEventListener('.btn-play-button', 'click', (ev) => {
    ev.preventDefault();
    let el = ev.target;
    let coverId = el.getAttribute('data-cover-id');
    let playerId = el.getAttribute('data-player-id');
    let coverEl = document.getElementById(coverId);
    if (coverEl){
      coverEl.style.display = 'none';
    }
      
    let contentPlayer = document.getElementById(playerId);
    if (contentPlayer){
      contentPlayer.play();
      contentPlayer.addEventListener('ended', () => {
        contentPlayer.pause();
        coverEl.style.display = 'block';
      })
    }
  })

})(window);
