import 'core-js/stable';
require("../css/play.css");
require('./hammer.js');
require('./navbar.js');
require('./accordion.js')
require('./video.js')
require('./curriculum.js')
require('./tabs.js')
require("./article.js");

import elementClosest  from 'element-closest';

import { addCSSClass, removeCSSClass, toggleCSSClass, aflAddEventListener, elementWidth, 
  setHeight, getOffset, isDesktopWidth, fixedHeaderHeight } from './utils';

import {checkTouch} from './check-touch.js'
import { polyfill } from 'smoothscroll-polyfill';

import vhCheck from 'vh-check'

polyfill(); // smoothscroll polyfill
elementClosest(window); // activate polyfill

(function(window) {
  /* Some browser detection */
  var ua = window.navigator.userAgent;
  var iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
  var webkit = !!ua.match(/WebKit/i);
  var iOSSafari = iOS && webkit && !ua.match(/CriOS/i);

  if (iOSSafari){
    addCSSClass('body', 'ios-safari');
  }

  var iPad = !!(navigator.userAgent.match(/(iPad)/)
    || (navigator.platform === "MacIntel" && typeof navigator.standalone !== "undefined"))
  if (iPad){
    addCSSClass('body', 'ios-ipad');
  }

  const test = vhCheck()
  window.dataLayer = window.dataLayer || [];

  function hideAddressBar(){
    if(!window.location.hash){
        if(document.height < window.outerHeight){
            document.body.style.height = (window.outerHeight + 50) + 'px';
        }
        setTimeout( function(){ window.scrollTo(0, 1); }, 50 );
    }
  }

  /* Scroll Icon functionality */
  if (isDesktopWidth()){
    var scrollIconTimeout = null;
    setInterval(function(){
      clearTimeout(scrollIconTimeout);
      toggleCSSClass('.scroll-icon', 'hover');
      scrollIconTimeout = setTimeout(function (){
        toggleCSSClass('.scroll-icon', 'hover')
      }, 800);
    }, 2500);
  }

  aflAddEventListener('.scroll-icon', 'click', (ev) => {
    ev.preventDefault();
    let scrollToEl = document.getElementById('scroll-to');
    if (scrollToEl != null){
      window.scrollTo({ top: getOffset(scrollToEl).top, behavior: 'smooth'});
    }
  });


  aflAddEventListener('.js-scroll-to', 'click', (ev) => {
    ev.preventDefault();
    let el = ev.target;
    let scrollToId = el.getAttribute('href').replace(/^\/|\/$/g, '');
    let scrollToEl = document.querySelector(scrollToId); // use queryselector because it has the hash at the start
    if (scrollToEl){
      let offset = getOffset(scrollToEl);
      window.scrollTo({ top: offset.top - fixedHeaderHeight() - 20, behavior: 'smooth'})
    }
  })
 
  function onPageLoad(){
    if(!window.pageYOffset){ hideAddressBar();} 
  }

  if (window.addEventListener){
    window.addEventListener('load', onPageLoad, false);
    window.addEventListener('orientationchange', hideAddressBar, false);
  } else if (window.attachEvent) {
    window.attachEvent('onload', onPageLoad);
    window.attachEvent('onorientationchange', hideAddressBar);
  }

  checkTouch();

})(window);
