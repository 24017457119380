import { isDesktopWidth, aflAddEventListener, toggleCSSClass, removeCSSClass,
  liveEventListener, getOffset, elementWidth, isMobileOrTabletWidth, addCSSClass } from './utils' 
import axios from 'axios';
import DOMPurify from 'dompurify';

/**
 * Functionality for the curriculum pages.
 * The scroller functions are also required for Resource Listings in the Article Listing pages
 */
(function(window, document) {

  aflAddEventListener('.curriculum-level-heading', 'click', (ev) => {
    ev.preventDefault();
    toggleCSSClass('.curriculum-level-selector', 'open');
  })

  aflAddEventListener('.action-load-remaining-weeks', 'click', (ev) => {
    ev.preventDefault();
    let el = ev.currentTarget;

    let weekSessionsContainer = document.querySelector('.week-sessions-container');
    if (weekSessionsContainer != null){
      weekSessionsContainer.style.display = 'block';
      el.style.display = 'none';
    } else {
      let level = el.getAttribute('data-level');
      let start = el.getAttribute('data-start-week');
      var url = '/ajax/curriculum/remaining_weeks/'+ level
      if (start){
        url += "/" + start
      }

      let weeksContent = document.querySelector('.weeks-content');
      axios.get(url).then((response) => {
 
        weeksContent.innerHTML = DOMPurify.sanitize(response.data);

        el.style.display = 'none';
        if (isDesktopWidth()){
          checkScrollerState();
        }
      })
    }
  });

liveEventListener('.weeks-content', '.action-hide-additional-weeks', 'click', (ev) => {
  ev.preventDefault();
  let anchorEl = document.getElementById('weeks-start');
  window.scrollTo({top: getOffset(anchorEl).top, behavior: 'smooth'})

  setTimeout(() => {
    document.querySelector('.week-sessions-container').style.display = 'none';
    document.querySelector('.action-load-remaining-weeks').style.display = 'inline-block';
  }, 200)

})

  function checkScrollerState(reset){
    if (reset) scroller_round_width = 0;
    let scrollerInners = document.querySelectorAll('.long-activities-row-inner')
    scrollerInners.forEach((el) => {
      let innerEl = el.children[0];
      let id = el.getAttribute('data-id');
      let slideCount = el.children.length;
      let parent = el.parentNode;
      let parentInnerWidth = parseInt(window.getComputedStyle(parent).width);
      let outerWidth = elementWidth(innerEl);
      items_in_scroller_viewport[id] = Math.round(parentInnerWidth / outerWidth);
      var slideWidth = slideCount * (outerWidth + parseInt(innerEl.style.marginRight));
      el.style.width =  slideWidth + 'px';
      if (isMobileOrTabletWidth()){
        addCSSClass('.round-scroller-button', 'hide');
      } else if (outerWidth * slideCount > (parentInnerWidth + 30)){ // give us 20px grace
        removeCSSClass('.scroller-button-next-'+id, 'hide')
      } 

      if (reset){
        scroller_offsets[id] = 1;
        moveLongScroller(0, id);
        addCSSClass('.scroller-button-prev-'+id, 'hide');
      }
    });
  }

  var scroller_round_width = 0;
  var scroller_offsets = [];
  var items_in_scroller = [];
  var items_in_scroller_viewport = [];

  checkScrollerState();

  function getScrollerRoundWidth(id){
    if (scroller_round_width == 0){
      scroller_round_width = elementWidth('.long-activities-row-inner-'+id + ' .activity-block');
    }
    return scroller_round_width;
  }

  function getItemsInScroller(id){
    if (! (id in items_in_scroller)){
      items_in_scroller[id] = document.querySelectorAll('.long-activities-row-inner-'+id + ' .activity-block').length;
    }

    return items_in_scroller[id];
  }

  function moveScrollerNext(el){
    let id = el.getAttribute('data-id');
    if (! (id in scroller_offsets))
      scroller_offsets[id] = 1;

    if (scroller_offsets[id] <= getItemsInScroller(id) - items_in_scroller_viewport[id]){
      scroller_offsets[id] += items_in_scroller_viewport[id];
      var round_width = getScrollerRoundWidth(id);

      moveLongScroller(round_width*((scroller_offsets[id]-1)*-1), id);
      removeCSSClass('.scroller-button-prev-'+id, 'hide');

      if (scroller_offsets[id] > getItemsInScroller(id) - items_in_scroller_viewport[id]){
        addCSSClass(el, 'hide');
      }
    }
  }

  liveEventListener(null, '.scroller-button-next', 'click', (ev) => {
    ev.preventDefault();
    moveScrollerNext(ev.liveTarget);
  });

  function moveScrollerPrev(el){
    let id = el.getAttribute('data-id');
    if (! (id in scroller_offsets))
      scroller_offsets[id] = 1;

    if (scroller_offsets[id] > 1){
      scroller_offsets[id] -= items_in_scroller_viewport[id];
      var round_width = getScrollerRoundWidth(id);
      moveLongScroller(round_width*((scroller_offsets[id]-1)*-1), id);
      removeCSSClass('.scroller-button-next-'+id, 'hide');

      if (scroller_offsets[id] === 1){
        addCSSClass(el, 'hide');
      }
    }
  }

  liveEventListener(null, '.scroller-button-prev', 'click', (ev) => {
    ev.preventDefault();
    moveScrollerPrev(ev.liveTarget);
  });

  var moveLongScroller = function(pos, id){
    let animationTime = 500;
    let easing = "ease";
    let scrollerInner = document.querySelector('.long-activities-row-inner-'+id);
    let width = parseInt(scrollerInner.style.width);
    var transformCSS = "-webkit-transform: translate3d( " + pos + "px, 0, 0); -webkit-transition: -webkit-transform " + animationTime + "ms " 
      + easing + "; -moz-transform: translate3d(" + pos + "px, 0, 0); -moz-transition: -moz-transform " + animationTime + "ms " + easing +
       "; -ms-transform: translate3d(" + pos + "px, 0, 0); -ms-transition: -ms-transform " + animationTime + "ms " + easing + "; transform: translate3d(" 
       + pos + "px, 0, 0); transition: transform " + animationTime + "ms " + easing + "; width:" + width + 'px';
    
    scrollerInner.style.cssText = transformCSS;
  }

  aflAddEventListener('.action-load-more-tips', 'click', (ev) => {
    ev.preventDefault();
    document.querySelector('.show-tips').style.display = 'none';
    addCSSClass('.activity-tips-container', 'open');
  })

  if (window.addEventListener){
    window.addEventListener('resize', checkScrollerState, false);
  } 
  else if (window.attachEvent){
    window.attachEvent('onresize', checkScrollerState);
  }

})(window, document);
