  import 'core-js/stable';

  export const MOBILE_MAX_WIDTH = 640;
  export const TABLET_PORTRAIT_MAX_WIDTH = 768;
  export const TABLET_MAX_WIDTH = 1024;
  export const LARGE_DESKTOP_MIN_WIDTH = 1280;
  export const NAVBAR_THRESHOLD = 150;

  export function validateEmail(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  /** Throttle function so we don't need to import lodash */
  export function throttle(func, wait, options) {
    var context, args, result;
    var timeout = null;
    var previous = 0;
    if (!options) options = {};
    var later = function() {
      previous = options.leading === false ? 0 : Date.now();
      timeout = null;
      result = func.apply(context, args);
      if (!timeout) context = args = null;
    };
    return function() {
      var now = Date.now();
      if (!previous && options.leading === false) previous = now;
      var remaining = wait - (now - previous);
      context = this;
      args = arguments;
      if (remaining <= 0 || remaining > wait) {
        if (timeout) {
          clearTimeout(timeout);
          timeout = null;
        }
        previous = now;
        result = func.apply(context, args);
        if (!timeout) context = args = null;
      } else if (!timeout && options.trailing !== false) {
        timeout = setTimeout(later, remaining);
      }
      return result;
    };
  };

  export function toTitleCase(str){
    if (str == null) return '';
    let cantTouchThis = {
        'AFL': 'AFL', 'JFC' : 'JFC', 'JAFC': 'JAFC', 'A.F.C.': 'A.F.C.', '(NFNL)': '(NFNL)', 
        'WFC': 'WFC', 'AAA': 'AAA', '(FIDA)': '(FIDA)', 'FC': 'FC', '(SA)': '(SA)', '(NTJFA)': '(NTJFA)',
        'ACU': 'ACU', 'AFC': 'AFC', 'YCW': 'YCW', 'FL': 'FL', 'FL)': 'FL)', 'FC)': 'FC)'
      };
    return str.replace(/\w[\S.]*/g, function(txt){return cantTouchThis[txt.toUpperCase()] || txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();});
  }

  

  export function getScreenWidth(){
    return window.innerWidth
  }

  export function getScreenHeight(){
    return window.innerHeight;
  }

  export function isMobileWidth(){
      return getScreenWidth() < MOBILE_MAX_WIDTH;
  }

  export function isNotMobileWidth(){
    return getScreenWidth() >= MOBILE_MAX_WIDTH;
}

  export function isDesktopWidth(){
      return getScreenWidth() >= TABLET_MAX_WIDTH;
  }

  export function isLargeDesktopWidth(){
      return getScreenWidth() >= LARGE_DESKTOP_MIN_WIDTH;
  }

  export function isMobileOrTabletWidth(){
      return getScreenWidth() < TABLET_MAX_WIDTH;
  }

  export function isMobileOrTabletPortraitWidth(){
      return getScreenWidth() < TABLET_PORTRAIT_MAX_WIDTH;
  }

  export function isEmpty(obj) {
    for(var key in obj) {
        if(obj.hasOwnProperty(key))
            return false;
    }
    return true;
  }

  export function  elementOffset(el) {
    var rect = el.getBoundingClientRect(),
    scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
    scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    return { top: rect.top + scrollTop, left: rect.left + scrollLeft }
  }

  export function isElementInViewport (elem) {
    if (!elem) return false;

    let x = elem.getBoundingClientRect().left;
    let y = elem.getBoundingClientRect().top;
    let ww = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
    let hw = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
    let w = elem.clientWidth;
    let h = elem.clientHeight;
    return (
        (y < hw &&
         y + h > 0) &&
        (x < ww &&
         x + w > 0)
    );
  }

  /* If selector is a string, returns the element using document.querySelector, 
  otherwise assumes object is an element */
  export function getElement(selector){
    let el = null;
    if (typeof selector === 'string'){
      el = document.querySelector(selector);
    } else {
      el = selector;
    }

    return el;
  }

  /* Add a CSS class to a single element, if that element exists */
  export function addCSSClass(selector, cssClass){
    let el = getElement(selector);
    if (el){
      el.classList.add(cssClass)
    }
  }

  /* Remove a CSS class from a single element, if that element exists */
  export function removeCSSClass(selector, cssClass){
    let el = getElement(selector);
    if (el){
      el.classList.remove(cssClass)
    }
  }

  export function toggleCSSClass(selector, cssClass){
    let el = getElement(selector);
    if (el){
      if (el.classList.contains(cssClass)){
        el.classList.add(cssClass)
      } else {
        el.classList.remove(cssClass)
      } 
    }
  }

  /* Add a CSS class to multiple elements, if any of those elements exist */
  export function addCSSClassAll(selector, cssClass){
    let els = document.querySelectorAll(selector);
    els.forEach((el) => {
      el.classList.add(cssClass)
    })
  }

  /* Remove a CSS class from multiple elements, if any of those elements exist */
  export function removeCSSClassAll(selector, cssClass){
    let els = document.querySelectorAll(selector);
    els.forEach((el) => {
      el.classList.remove(cssClass)
    })
  }

  /* Convenience function to add an event listener to the first element that satisfies the provided selector.
  Given the prefix 'play' to avoid confusion with window.addEventListener function */
  export function aflAddEventListener(selector, eventType, callback, useCapture=false){
    let els = document.querySelectorAll(selector);
    els.forEach((el) => {
      el.addEventListener(eventType, (ev) => {
        callback.call(this, ev)
      }, useCapture);
    });
  }

  export function removeEventListener(selector, eventType, callback){
    let el = getElement(selector);
    if (el){
      el.removeEventListener(eventType, callback);
    }
  }

  /** Returns the scrollbar position (replacement for $(window).scrollTop()) */
  export function getScrollTop(){
    return window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0 ;
  }

  /** 
   * Returns an object array containing the top and left offset of the first element with the provided CSS selector.  
   * If selector can not be found, returns null
  */
  export function getOffset(selector){
    let el = getElement(selector);

    if (el && el.getBoundingClientRect){
      let rect = el.getBoundingClientRect();
      return { 
        top: rect.top + window.pageYOffset, 
        left: rect.left + window.pageXOffset, 
      };
    }
    return { top: 0, left: 0};
  }

  /** 
   * Returns an object array containing the top and left positions of the first element with the provided CSS selector 
   * relative to it's parent.  If selector can not be found, returns null
  */
  export function getPosition(selector){
    let el = getElement(selector);
    if (el)
      return { 
        top: el.offsetTop, 
        left: el.offsetLeft, 
    };

    return null;
  }

  /*  Returns the width of the first element with the provided CSS selector including left and right margins.
      Returns 0 if the element can not be found */
  export function elementWidth(selector){
    let el = getElement(selector);
    if (el){
      var style = el.currentStyle || window.getComputedStyle(el);
      
      return el.offsetWidth + parseFloat(style.marginLeft) + parseFloat(style.marginRight);
    }
    return 0;
  }

  export function elementHeight(selector) {
    let el = getElement(selector);
    if (el){
      var style = el.currentStyle || window.getComputedStyle(el);
      
      return parseFloat(style.height.replace("px", ""))
    }
    return 0;
  }

  export function setHeight(el, val) {
    if (typeof val === "function") val = val();
    if (typeof val === "string") el.style.height = val;
    else el.style.height = val + "px";
  }

  export function setWidth(el, val) {
    if (typeof val === "function") val = val();
    if (typeof val === "string") el.style.width = val;
    else el.style.width = val + "px";
  }

  export function showModal(modalClass) {
    addCSSClass('.modal-overlay', 'show');
    addCSSClass(modalClass, 'show');
  }

  export function hideModal(modalClass) {
    removeCSSClass('.modal-overlay', 'show');
    removeCSSClass(modalClass, 'show');
  }

  export function encodeStr(rawStr){
    return rawStr.replace(/[\u00A0-\u9999<>\&]/g, function(i) {
      return '&#'+i.charCodeAt(0)+';';
   });
  }

  /* Returns the height of the nav header at the top of the page */
  export function fixedHeaderHeight(){
    return elementHeight('.fixed-header') + elementHeight('.program-submenu');
  }

  /* Replacement for jquery el.slideToggle function. */
  export function slideToggle(container, question){
    if (!container.classList.contains('expanded')) {
      container.classList.add('expanded');
      question.classList.add("expanded");
      container.style.height = 'auto';

      let height = container.clientHeight + "px";
      container.style.height = '0px';

      setTimeout(function () {
          container.style.height = height;
      }, 0);
  } else {
      container.style.height = '0px';

      container.addEventListener('transitionend', function () {
          container.classList.remove('expanded');
          question.classList.remove("expanded");
      }, {
          once: true
      });
  }
}

/** Returns the siblings of the elements with the current selector */
export function getSiblings(selector){
  let el = getElement(selector);
  return Array.prototype.filter.call(el.parentNode.children, function(child){
    return child !== el;
  });
}

/*
  Convenience event listener method for elements added to the page dynamically.
  adds a custom "liveTarget" field to the event which is the element that was clicked on.
  Takes the following parameters:
  topElementQuerySelector: query selector for top level element from which to collect events.  If null defaults to document
  elementQuerySelector: query selector for element from which events are being collected
  eventType: type of the event
  cb: callback function for the event
*/
export function liveEventListener (topElementQuerySelector, elementQuerySelector, eventType, cb) {
  let topElement = document;
  if (topElementQuerySelector){
    topElement = document.querySelector(topElementQuerySelector);
  }
  if (topElement){
    topElement.addEventListener(eventType, function (ev) {
      let el = ev.target.closest(elementQuerySelector);
      ev.liveTarget = el; // add custom target to event
      if (el){
        cb.call(el, ev)
      }
    });
  }
}

export function deg2rad(deg) {
  return deg * (Math.PI/180)
}

/* Utility function to retrieve parameters from the querystring */
export function getParameterByName(name, parsed = null) {
  if (! parsed){
    parsed = Querystring.parse(location.search);
  }

  if (parsed[name])
    return parsed[name]
  else
    return null
}

/** Checks that a url begins with http or https, and if not, adds it. */
export function checkUrlForLink(url) {
  if (! url.startsWith('http://') && ! url.startsWith('https://')){
    url = 'https://' + url
  }
  return url;
}

/** Checks that a url begins with http or https, and if not, adds it. */
export function checkUrlForDisplay(url) {
  return url.replace('http://', '').replace('https://', '')
}

export function stringToSlug (str) {
  str = str.replace(/^\s+|\s+$/g, ''); // trim
  str = str.toLowerCase();

  // remove accents, swap ñ for n, etc
  var from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;";
  var to   = "aaaaeeeeiiiioooouuuunc------";
  for (var i=0, l=from.length ; i<l ; i++) {
      str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
  }

  str = str.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
      .replace(/\s+/g, '-') // collapse whitespace and replace by -
      .replace(/-+/g, '-'); // collapse dashes

  return str;
}


  

