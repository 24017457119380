if (document.getElementsByClassName("all-categories-section").length > 0) {
  const gap = 16;
  const width = document.querySelector(
    ".views-field-field-category-display"
  ).offsetWidth;
  function CreateCarousel() {
    const carousel = document.querySelector(
      ".views-field-field-category-display"
    );
    const prevButton = document.createElement("button");
    prevButton.innerText = "";
    prevButton.setAttribute("id", "previous");
    carousel.appendChild(prevButton);
    const prev = document.querySelector("#previous");
    const nextButton = document.createElement("button");
    nextButton.innerText = "";
    nextButton.setAttribute("id", "next");
    carousel.appendChild(nextButton);
    const next = document.querySelector("#next");
    const content = document.querySelector("div.field-content");
    const card = content.getElementsByClassName("activity-block");

    let count = Math.ceil(card.length / 4);
    let l = 0;
    let move = 102;
    let maxMove = 233;

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          next.style.display = "none";
        } else {
          next.style.display = "block";
        }
      });
    });
    observer.observe(card[card.length - 1], card[0]);

    const observerPrev = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          prev.style.display = "none";
        } else {
          prev.style.display = "block";
        }
      });
    });
    observerPrev.observe(card[0]);

    if (card.length - 4 > 0) {
      prev.style.display = "none";
    }

    let handle_next = () => {
      l = l + move;
      if (card == 1) {
        l = 0;
      }
      for (const i of card) {
        if (l > maxMove) {
          l = l - move;
        }
        i.style.left = "-" + l + "%";
        if (window.matchMedia("(min-width: 1920px)").matches) {
          i.style.left = "-106%";
        }
      }
    };
    let handle_prev = () => {
      l = l - move;
      if (l <= 0) {
        l = 0;
      }
      for (const i of card) {
        if (count > 1) {
          i.style.left = "-" + l + "%";
        }
      }
    };
   
    next.onclick = () => {
      handle_next();
    };
    prev.onclick = () => {
      handle_prev();
    };
  }
  let carousel = new CreateCarousel();
}
