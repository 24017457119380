import { aflAddEventListener, getSiblings, addCSSClass, 
  removeCSSClass, getElement, getOffset, fixedHeaderHeight } from './utils'

/**
 * Functionality for the tab controls used in our curriculum activity pages
 *  */  
(function(window, document) {

  function updateTabHeaders(selector){
    let linkEl = getElement(selector);
    if (linkEl){
      linkEl.style.display = 'block';
      let siblings = getSiblings(linkEl);
      siblings.forEach((tab) => {
        tab.style.display = 'none';
      });
    }
  }

  function updateTabBody(selector){
    let tabEl = getElement(selector);
    addCSSClass(tabEl, 'active');
    let siblings = getSiblings(tabEl);
    siblings.forEach((tab) => {
      removeCSSClass(tab, 'active');
    })
  }

  /* Event listener for links in tabbed header */
  aflAddEventListener('.tabs .tab-links a', 'click', (ev) => {
    ev.preventDefault();
    let el = ev.currentTarget;
    let currentAttrValue = el.getAttribute('href');
    updateTabHeaders('.tabs ' + currentAttrValue);
    updateTabBody(el.parentNode);
  });

  function gotoNextLink(linkEl){
    let currentAttrValue = linkEl.getAttribute('href');
    updateTabHeaders('.tabs ' + currentAttrValue);
    updateTabBody('.tab-links ' + currentAttrValue+'-link');
  }

  /* Event listener for next tab link at bottom of tab */
  aflAddEventListener('.tabs .next-tab', 'click', (ev) => {
    ev.preventDefault();
    let el = ev.currentTarget;
    let top = getOffset('.tabs-links-controls').top;
    window.scrollTo({ top: top - fixedHeaderHeight(), behavior: 'smooth'});

    gotoNextLink(el);
  });

  /* Set up swipe right functionality */
  let tabEls = document.querySelectorAll('.tabs .tab-content .tab');
  tabEls.forEach((tabEl) => {
    let nextLink = tabEl.querySelector('a.next-tab');
    var mc = new Hammer.Manager(tabEl);
    mc.add(new Hammer.Swipe({ threshold: 5, pointers: 1 }) );
    mc.get('swipe').set({ direction: Hammer.DIRECTION_HORIZONTAL });
    mc.on('swipeleft', function(e) {
      gotoNextLink(nextLink);
    });
  });

})(window, document);
