import { addCSSClass, removeCSSClass, getScreenWidth, 
  isMobileOrTabletWidth, aflAddEventListener, removeCSSClassAll, getPosition, fixedHeaderHeight,
  getScreenHeight, getOffset, getScrollTop, elementWidth, throttle } from './utils';
import cookie from 'cookie';

/**
 * 
 * Handles the navigation bar functionality 
 * 
 */
var rsTimeout;
(function(window) {

  // set up some element variables

  function calculateMenuCenters(){
    let arrowWidth = 20;

    let navWaysPos = getPosition('.nav-ways');
    if (! navWaysPos) return;

    let programArrowleft = navWaysPos.left + (elementWidth('.nav-ways') / 2) - arrowWidth;
    
    document.querySelector('.programs-menu-arrow').style.left = programArrowleft + 'px';

    let burgerCenter = getPosition('.burger-button').left;
    let mainMenu = document.querySelector('.main-menu-container');
    
    if (mainMenu){
      var style = window.getComputedStyle ? getComputedStyle(mainMenu, null) : mainMenu.currentStyle;
      let mainMenuWidth = style.width.replace(/\D/g, '');

      let mainLeft = burgerCenter - mainMenuWidth * .75;
      let windowClearance = getScreenWidth() - 15 - mainMenuWidth;

      if (mainLeft > windowClearance){
        mainLeft = windowClearance;
      }

      document.querySelector('.main-menu-container').style.left = mainLeft + 'px';
      document.querySelector('.main-menu-arrow').style.left = (burgerCenter - mainLeft) + 'px';
    }
  }

  function checkNavOverflowing(element){
    return element.offsetWidth < element.scrollWidth
  }

  /* Determines how far along we are in the program subnav, and whether we can scroll left or right using the arrows */
  function checkProgramSubnavScrollState(){
    let navEl = document.querySelector('.program-submenu-nav-container ul.menu');
    if (checkNavOverflowing(navEl)){
      if (navEl.scrollLeft === (navEl.scrollWidth - navEl.offsetWidth)){ // scrolled to end
        removeCSSClass('.nav-next', 'active');
      } else {
        addCSSClass('.nav-next', 'active');
      }

      if (navEl.scrollLeft > 0){
        addCSSClass('.nav-prev', 'active');
      } else {
        removeCSSClass('.nav-prev', 'active');
      }
      
    } else {
      removeCSSClass('.nav-prev', 'active');
      removeCSSClass('.nav-next', 'active');
    }
  }

  function checkProgramSubnav(){
    let subNavContainer = document.querySelector('.program-submenu-nav-container');
    if (subNavContainer !== null && isMobileOrTabletWidth()){
      let navEl = document.querySelector('.program-submenu-nav-container ul.menu');
      navEl.addEventListener('scroll', throttle(checkProgramSubnavScrollState, 25))
      window.addEventListener('resize', throttle(checkProgramSubnavScrollState, 25))
      checkProgramSubnavScrollState();
    }
  }

  checkProgramSubnav();

  /**
   * Listen to scroll to change header opacity class and the state of our header
   */

  function checkScroll(){
    
      var scrollTop = getScrollTop();
      var startY = fixedHeaderHeight(); //The point where the navbar changes in px
      var mapY = 0;
      var windowHeight = getScreenHeight()

      if (document.getElementById("map_locator") !== null){
        mapY = getOffset("#map_locator").top;
      }

      if(scrollTop > startY){
        addCSSClass('.fixed-header', 'scrolled');
          
      }else{
        removeCSSClass('.fixed-header', 'scrolled')
      }
      if(scrollTop > mapY - 200){
        addCSSClass('.btn-find-your-way', 'scrolled');
      }else{
        removeCSSClass('.btn-find-your-way', 'scrolled');
      }
  }

  function checkResize(){
    calculateMenuCenters();
    checkScroll();
  }

  if(document.querySelector('.fixed-header') !== null){
    window.addEventListener('scroll', function(){ checkScroll()})
    window.addEventListener('resize', function(){ checkResize()})
    window.addEventListener('load', function(){ checkScroll()})
    window.addEventListener('orientationchange', function(){ 
      setTimeout(function(){
        checkResize();
      }, 300);
    })
  }

  let mainMenuActive = false;
  
  function doMenuToggle(selector){
    let menuEl = document.querySelector(selector);
      if (menuEl){
        if (! menuEl.classList.contains('active')){
          addCSSClass('.dropdown-menu-container', 'show-contents')
          setTimeout(() => {
            menuEl.classList.add('active');
          }, 10);
        } else {
          menuEl.classList.remove('active');
          containerMenuHoverTimeout = setTimeout(() => {
            removeCSSClass('.dropdown-menu-container', 'show-contents');
          }, 250);
        }
      }
  }

  /**  Toggle menu visibility for mobile devices */
  function toggleMenu(ev){
    ev.stopPropagation();
    ev.preventDefault();
    if (isMobileOrTabletWidth())
    {
      if (! mainMenuActive){
        addCSSClass('.burger-button', 'active');
        addCSSClass('#header', 'scrolled');
        addCSSClass('html', 'mob-menu-showing');
        addCSSClass('.dropdown-menu-container', 'active');
        setTimeout(function(){
          addCSSClass('.dropdown-menu-container', 'visible');
        }, 250)
      } else {
        removeCSSClass('.burger-button', 'active');
        removeCSSClass('#header', 'scrolled');
        removeCSSClass('html', 'mob-menu-showing');
        removeCSSClass('.dropdown-menu-container', 'visible');
        setTimeout(function(){
          removeCSSClass('.dropdown-menu-container', 'active');
        }, 250);
      }
      mainMenuActive = ! mainMenuActive;
    } else {
      doMenuToggle('.main-menu-container');
    }
  }

  aflAddEventListener('.toggle-menu', 'touchend', toggleMenu);

  aflAddEventListener('.toggle-menu', 'click', (ev) => {
    if (isMobileOrTabletWidth()){
      toggleMenu(ev);
    }
  });

  let programMenuHoverTimeout = null;
  let containerMenuHoverTimeout = null;
  let mainMenuHoverTimeout = null;

  aflAddEventListener('.nav-ways', 'mouseover', () => {
    clearTimeout(programMenuHoverTimeout);
    clearTimeout(containerMenuHoverTimeout);
    clearTimeout(mainMenuHoverTimeout);
    addCSSClass('.dropdown-menu-container', 'show-contents');
    removeCSSClass('.main-menu-container', 'active');

    containerMenuHoverTimeout = setTimeout(() => {
      addCSSClass('.programs-menu-container', 'active');
    }, 10);
  })

  aflAddEventListener('.nav-ways', 'mouseout', () => {
    programMenuHoverTimeout = setTimeout(function(){
      removeCSSClass('.programs-menu-container', 'active');
      containerMenuHoverTimeout = setTimeout(function(){
        removeCSSClass('.dropdown-menu-container', 'show-contents')
      }, 250);
    }, 1000)
  });

  aflAddEventListener('.nav-ways', 'touchend', (ev) => {
    ev.preventDefault();
    ev.stopPropagation();

    if (isMobileOrTabletWidth()) return;

    doMenuToggle('.programs-menu-container');
  });

  aflAddEventListener('.program-menu-item a', 'touchend', (ev) => {
    ev.stopPropagation();
    removeCSSClass('.programs-menu-container', 'active');
    let el = ev.currentTarget;
    window.location.href = el.getAttribute('href');
  });

  aflAddEventListener('html', 'touchend', function(ev){
    removeCSSClassAll('.programs-menu-container, .main-menu-container', 'active');
    setTimeout(function(){
      removeCSSClass('.dropdown-menu-container', 'show-contents');
    }, 250);
  });

  aflAddEventListener('.programs-menu-container', 'mouseover', () => {
    clearTimeout(programMenuHoverTimeout);
    clearTimeout(containerMenuHoverTimeout);
  });

  aflAddEventListener('.programs-menu-container', 'mouseout', () => {
    programMenuHoverTimeout = setTimeout(function(){
      removeCSSClass('.dropdown-menu-container', 'show-contents');
      containerMenuHoverTimeout = setTimeout(function(){
        removeCSSClass('.dropdown-menu-container', 'show-contents');
      }, 250);
    }, 1000)
  });

  aflAddEventListener('.burger-button', 'mouseover', () => {
    clearTimeout(programMenuHoverTimeout);
    clearTimeout(mainMenuHoverTimeout);
    clearTimeout(containerMenuHoverTimeout);
    removeCSSClass('.programs-menu-container', 'active');
    addCSSClass('.dropdown-menu-container', 'show-contents')
    addCSSClass('.main-menu-container', 'active')
  })

  function closeDropdownMenu(ev){
    mainMenuHoverTimeout = setTimeout(function(){
      removeCSSClass('.main-menu-container', 'active')
      containerMenuHoverTimeout = setTimeout(function(){
        removeCSSClass('.dropdown-menu-container', 'show-contents');
      }, 250);
    }, 1000)
  }

  aflAddEventListener('.burger-button', 'mouseout', closeDropdownMenu);
  aflAddEventListener('.main-menu-container', 'mouseout', closeDropdownMenu);

  aflAddEventListener('.main-menu-container', 'mouseover', () => {
    clearTimeout(mainMenuHoverTimeout);
    clearTimeout(containerMenuHoverTimeout);
  })

  let navEl = document.querySelector('.program-submenu-nav-container ul.menu');

  aflAddEventListener('.program-submenu-nav-container .nav-next', 'touchend', (ev) => {
    ev.preventDefault();
    navEl.scroll({ 'left': navEl.scrollWidth, behavior: 'smooth' });
  })

  aflAddEventListener('.program-submenu-nav-container .nav-prev', 'touchend', (ev) => {
    ev.preventDefault();
    navEl.scroll({ 'left': 0, behavior: 'smooth' });
  });

  if (window.addEventListener){
    window.addEventListener('load', calculateMenuCenters, false);
  } 
  else if (window.attachEvent){
    window.attachEvent('onload', calculateMenuCenters);
  }

  /** Handle removing of notification strip at the top of the page */

  aflAddEventListener('.action-hide-notification-bar', 'click', (ev) => {
    ev.preventDefault();
    addCSSClass('.notification-strip', 'remove');
    let el = ev.currentTarget;
    let bid = el.getAttribute('data-bid');
    let setCookie = cookie.serialize(bid +'-rego-bar-removed', 'true', { maxAge: 60 * 60 * 24 * 30, path: '/'});
    document.cookie = setCookie;
    window.setTimeout(() => {
      removeCSSClass('#page-wrapper', 'has-notification-bar')
    }, 0);
  })

})(window);
